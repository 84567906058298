<script setup>
import { defineComponent } from 'vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

const props = defineProps({
  events: {
    type: Object,
    required: true
  }
});
    const timeFrom = 7*60
    const timeTo = 22*60
    const disableViews = ['years', 'year', /*'month',*/ 'week', 'day']

    const showTime = false


// import { computed } from 'vue'

// const computedEvents = computed(() => {
//   if (events.length === 0) {
//     return []
//   }
//   return events
// })


</script>

<template>
  <vue-cal
  class="h-[600px]"
  show-all-day-events="short"
  events-on-month-view="short"
    :events="props.events"
    :disable-views="disableViews"
    hide-view-selector
    startWeekOnSunday
    watchRealTime
    :minDate="new Date()"
  >
  </vue-cal>
</template>

<style>
/* Different color for different event types. */
.vuecal__event.leisure {
  /* background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46); */
  background-color: var(--blue);
  border: 1px solid var(--blue); 
  color: #fff;}

  
.vuecal__event.sport {background-color: rgba(255, 102, 102, 0.9);border: 1px solid rgb(235, 82, 82);color: #fff;}

.vuecal__event.Black {
  background-color: #000;
  color: white;
}
.vuecal__now-line {border-top-width: 6px;}
.vuecal__cell--today, .vuecal__cell--current {
  @apply outline-4 outline outline-red-600 bg-red-100 dark:bg-red-950 dark:outline-red-700
}

.vuecal__cell--today::before {
  content: "Today";
  @apply font-semibold;
}

.vuecal__title-bar {
  /* background-color: var(--secondary-callout-default); */
background-color: #3f3f3f;
}

.vuecal__title {
  @apply text-white
}

.vuecal--month-view .vuecal__cell {
  /* height: 90px; */
  /* min-height: 90px; */
}

.vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {padding: 4px;}
.vuecal--month-view .vuecal__no-event {display: none;}

.vuecal__cell--before-min {
  color: var(--text);
  opacity: 0.50;
  text-decoration: line-through;
}

.vuecal__cell--out-of-scope {
  color: var(--secondary-callout)
}
</style>