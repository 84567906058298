<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useSettingsStore } from '../stores/settings'
const store = useSettingsStore()
import Agenda from "./Agenda.vue"

import { getDayName, getDayOfMonth, getRelativeDayName, isTodayOrTomorrow, getDaysDifference, dateToISODate, addDays } from "../utils/dateUtils";

import { groupUpcomingEvents } from '../utils/eventUtils'

const props = defineProps({
  events: {
    type: Object,
    required: true
  }
});

// const formattedTime = new Intl.DateTimeFormat('en-US', {

//   hour: 'numeric',
//   minute: '2-digit',
//   hour12: true,
//   timeZone: "America/New_York",
// });

// const formattedDate = new Intl.DateTimeFormat('en-US', {
//   year: 'numeric',
//   month: '2-digit',
//   day: '2-digit',
//   timeZone: "America/New_York",
// });



const timeFrom = "08:00";
const timeTo = "20:00";
const disableViews = ["years", "year", "month", "week", "day"];
// const format = {
//   timeslotTime: formatTime,
// };

// const upcomingEvents = computed(() => {
//   return props.events
//     .filter((event) => new Date(event.end) >= new Date())
//     .slice(0, 5);
// });

// const todayEvents = computed(() => {
//   return filteredEvents.value
//     .filter(event => isThisDay(event.start.toISOString()))
// })

const todayEventsGrouped = computed(() => {
  const today = dateToISODate(new Date())
  return upcomingEventsGrouped.value?.[today] ?? []
})

// const tomorrowEvents = computed(() => {
//   return filteredEvents.value
//     .filter(event => isThisDay(event.start.toISOString(), 1))
// })

const tomorrowEventsGrouped = computed(() => {
  const tomorrow = dateToISODate(addDays(new Date(), 1))  
  
  return upcomingEventsGrouped.value?.[tomorrow] ?? []
})

// const inXDaysEvents = computed(() => {
//   // filter out today and tomorrow
//   const events = filteredEvents.value
//     .filter((event) => new Date(event.end) >= new Date())
//     .filter(event => !isTodayOrTomorrow(event.start.toISOString()))
  
//   // get the next day
//   const nextDay = events[0].start
//   const first = dateToISODate(nextDay)
//   const today = dateToISODate(new Date())
//   console.log('first', first)
//   console.log('today', today)
  
  
//   const diff = getDaysDifference(new Date(dateToISODate(nextDay)), new Date(dateToISODate((new Date()))))
//   console.log('diff', diff)
//   console.log('events[0].start', events[0].start)
  
//   const dayX = events
//     .filter(event => isThisDay(event.start.toISOString(), diff ))
//     //Filter that day
//     console.log('dayX', dayX)
//     return dayX
    
// })

const xDaysDate = computed(() => {
  // Remove today and tomorrow if there
  const currentDay = dateToISODate(new Date())
  const nextDay = dateToISODate(addDays(new Date(), 1))

  const allKeys = Object.keys(upcomingEventsGrouped.value)

  if (allKeys.includes(currentDay)) {
    allKeys.shift()
  }

  if (allKeys.includes(nextDay)) {
    allKeys.shift()
  }
  // Return the next date in the list
  const next =  allKeys[0] ?? ''
  return next
  
})

const inXDaysEventsGrouped = computed(() => {
  return upcomingEventsGrouped.value[xDaysDate.value] ?? []
})

// const xDaysDate = computed(() => {
//   if (inXDaysEvents.value.length) {
//     const theDate = dateToISODate(inXDaysEvents.value[0])
//     console.log('theDate', theDate)
    
//   }
//   return dateToISODate(new Date())
// })

const xDaysCount = computed(() => {
  if (inXDaysEventsGrouped.value.length) {

    const word = numberToWord(getDaysDifference(new Date(xDaysDate.value), new Date(dateToISODate((new Date())))))
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return ''
})

const upcomingEventsLimit = 5
const upcomingEventsDaysCutoff = 7

const filteredEvents = ref([])

const upcomingEventsGrouped = computed(() => {
  return groupUpcomingEvents(filteredEvents.value, upcomingEventsLimit, upcomingEventsDaysCutoff)
});

const upcomingEventsGroupedAfterTomorrow = computed(() => {
  const today = dateToISODate(new Date());
  const tomorrow = dateToISODate(addDays(new Date(), 1));
  const eventsAfterTomorrow = Object.entries(upcomingEventsGrouped.value).reduce((acc, [date, events]) => {
    if (date !== today && date !== tomorrow) {
      acc[date] = events;
    }
    return acc;
  }, {});

  return eventsAfterTomorrow;
})

const hasUpcomingEvents = computed(() => {
  return Object.entries(upcomingEventsGrouped.value).length
})

const currentDate = ref(new Date())

watch(() => props.events, (newEvents, oldEvents) => {
  updateFilteredEvents()
}, { deep: true });

// This only runs the grouping if the events have actually changed from what we currently have.
const updateFilteredEvents = () => {
  const current = props.events.filter((event) => new Date(event.end) >= new Date())
  const comparison = JSON.stringify(current) === JSON.stringify(filteredEvents.value)
  // console.log('current', current)
  // console.log('filteredEvents.value', filteredEvents.value)
  const didTodayChange = new Date().getDate() !== currentDate.value.getDate()
  
  // console.log("Result", comparison);
  if (!comparison || didTodayChange) {
    // console.log("Updating");
    filteredEvents.value = current
    currentDate.value = new Date()
  }
}

const numberToWord = (num) => {
  const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 
    'eighteen', 'nineteen'];
  
  const tens = ['', '', 'twenty', 'thirty'];

  // Handle invalid input
  if (num < 1 || num > 31 || !Number.isInteger(num)) {
    throw new Error('Please provide an integer between 1 and 31');
  }

  // Handle 1-19
  if (num < 20) {
    return units[num];
  }
  
  // Handle 20-31
  const tensDigit = Math.floor(num / 10);
  const unitsDigit = num % 10;
  
  return unitsDigit === 0 
    ? tens[tensDigit]
    : `${tens[tensDigit]}-${units[unitsDigit]}`;
}

let interval

onMounted(() => {
  interval = setInterval(() => {
    updateFilteredEvents()
  }, 60*1000)
  return () => clearInterval(interval);
});

onUnmounted(() => {
  clearInterval(interval)
})

// Before or after?
const agendaTimePosition = "after"

const showPlayer = ref(false)
const showButtons = ref(false)

const clickAudio = () => {
  const audioElements = document.querySelector('audio')
  console.log('audioElements', audioElements)
  try {
    audioElements.play()
  } catch (error) {
    console.log('error', error)
    
  }
  
  
}

const shouldShowLoadingText = computed(() => {
  return (store.calendars?.length ?? 0) && store.hasEnabledCalendars && !(props.events?.length ?? 0)
})

</script>

<template>
  <div>
    <!-- <vue-cal
      :events="events"
      :time-from="timeFrom"
      :time-to="timeTo"
      :disable-views="disableViews"
      :format="format"
    /> -->

    <div class="mx-auto max-w-6xl px-4 mt-0">
      <h1  @click="showButtons = !showButtons"  class="font-bold text-5xl mt-0 mb-2 leading-normal">Today<span v-if="store.isOffline" class="float-right inline-block p-1 rounded-full text-xs text-center min-w-[2.1em] leading-none bg-sky-600 dark:bg-sky-900 text-white"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m3 3 8.735 8.735m0 0a.374.374 0 1 1 .53.53m-.53-.53.53.53m0 0L21 21M14.652 9.348a3.75 3.75 0 0 1 0 5.304m2.121-7.425a6.75 6.75 0 0 1 0 9.546m2.121-11.667c3.808 3.807 3.808 9.98 0 13.788m-9.546-4.242a3.733 3.733 0 0 1-1.06-2.122m-1.061 4.243a6.75 6.75 0 0 1-1.625-6.929m-.496 9.05c-3.068-3.067-3.664-7.67-1.79-11.334M12 12h.008v.008H12V12Z" />
</svg>
</span></h1>
      <audio v-show="showPlayer" controls>
  <source src="/hotel-bell-ding.mp3" type="audio/mpeg" />
  <!-- fallback for non-supporting browsers goes here -->
  <p>
    Your browser does not support HTML audio, but you can still
    <a href="/hotel-bell-ding.mp3">download the music</a>.
  </p>
</audio>
<button v-show="showButtons" type="button" class="btn btn-primary" @click="showPlayer= !showPlayer">Show player</button>
                <!-- <audio v-show="true" src="/hotel-bell-ding.mp3" type="audio/mpeg" autoplay="" muted="false" playsinline=""></audio> -->
              <button v-show="showButtons" id="alertSound" class="btn btn-primary" type="button" @click="clickAudio()">Alert</button>
      <h2 v-if="!store.hasEnabledCalendars" class="text-[2.5rem] mt-0 mb-2 leading-normal">Add a calendar to get started. <RouterLink class="btn btn-primary text-xl" to="/preferences">Add Calendar</RouterLink></h2>
      <template v-else>
      <h3 v-if="shouldShowLoadingText" class="text-[2.5rem] mt-0 mb-2 leading-normal">Loading Events</h3>
      <Transition name="fade">
        <div v-if="!shouldShowLoadingText">

          <!-- Today Section -->
          
          <div v-if="!todayEventsGrouped.length" class="callout round bg-neutral-200 dark:bg-neutral-900 border-dashed border-neutral-400 dark:border-neutral-700 border-4">
            <h2 class="text-[2rem] mt-0 mb-2 leading-normal font-semibold text-neutral-500">No Events today</h2>
          </div>
          <TransitionGroup name="list" tag="div">
            <template v-for="event in todayEventsGrouped" :key="event.start">
              <Agenda :event="event" :day="dateToISODate(new Date())" :agenda-time-position="agendaTimePosition" @alert="clickAudio"></Agenda>
            </template>
          </TransitionGroup>
          <!-- Tomorrow Section -->
          <h1 class="font-bold text-5xl mt-0 mb-2 leading-normal">Tomorrow</h1>
          <div v-if="!tomorrowEventsGrouped.length" class="callout round bg-neutral-200 dark:bg-neutral-900 border-dashed border-neutral-400 dark:border-neutral-700 border-4">
            <h2 class="text-[2rem] mt-0 mb-2 leading-normal font-semibold text-neutral-500">No Events tomorrow</h2>
          </div>
          <TransitionGroup name="list" tag="div">
            <template v-for="event in tomorrowEventsGrouped" :key="event.start">
              <Agenda :event="event" :day="dateToISODate(addDays(new Date(), 1))" :agenda-time-position="agendaTimePosition" @alert="clickAudio"></Agenda>
            </template>
          </TransitionGroup>

          <hr class="border-2 mt-8 mb-4">

          <!-- Next Day -->
          <!-- <h1 class="font-bold text-5xl mt-0 mb-2 leading-normal">In {{ xDaysCount }} Days</h1>
          <TransitionGroup name="list" tag="div">
            <template v-for="event in inXDaysEventsGrouped" :key="event.start">
              <Agenda :event="event" :day="xDaysDate" :agenda-time-position="agendaTimePosition" @alert="clickAudio"></Agenda>
            </template>
          </TransitionGroup> -->

          <template v-if="hasUpcomingEvents">
            <TransitionGroup name="list" tag="div">
              <div v-for="(day, key) in upcomingEventsGroupedAfterTomorrow" :key="key">
                <h1 class="font-medium text-4xl mt-0 mb-2 leading-normal" v-if="isTodayOrTomorrow(key)">{{ getDayOfMonth(key) }}  {{ getDayName(key) }} - {{ getRelativeDayName(key) }}</h1>
                <h1 v-else class="font-medium text-4xl mt-0 mb-2 leading-normal">{{ getDayOfMonth(key) }} {{ getDayName(key) }}</h1>
                <template v-for="event in day" :key="event.start">
                  <Agenda :event="event" :day="key" :agenda-time-position="agendaTimePosition" @alert="clickAudio"></Agenda>
                </template>
                
              </div>
            </TransitionGroup>
          </template>
          <template v-else>
            <h3 class="text-4xl mt-6 mb-2">No events in the next 7 days.</h3>
          </template>

          <!-- <template v-if="hasUpcomingEvents">
            <TransitionGroup name="list" tag="div">
              <div v-for="(day, key) in upcomingEventsGrouped" :key="key">
                <h2 class="text-[2.5rem] mt-0 mb-2 leading-normal" v-if="isTodayOrTomorrow(key)">{{ getDayOfMonth(key) }}  {{ getDayName(key) }} - {{ getRelativeDayName(key) }}</h2>
                <h2 v-else class="text-[2.5rem] mt-0 mb-2 leading-normal">{{ getDayOfMonth(key) }} {{ getDayName(key) }}</h2>
                <template v-for="event in day" :key="event.start">
                  <Agenda :event="event" :day="key" :agenda-time-position="agendaTimePosition" @alert="clickAudio"></Agenda>
                </template>
                
              </div>
            </TransitionGroup>
          </template>
          <template v-else>
            <h3 class="text-3xl mt-0 mb-2">No events in the next 2 weeks</h3>
          </template> -->
        </div>
      </Transition>
    </template>
    </div>
  </div>
</template>


<style>

.event-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.event-time {
  margin-right: 1rem;
  font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* .list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
} */
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>